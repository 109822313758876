.page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    background-image: url('https://ceadventures.life/wp-content/uploads/2020/04/cropped-cropped-cropped-bell_harbor_marina_1-1.jpg');
    background-attachment: fixed;
    overflow-y: auto;
}

.content {
    position: absolute;
    top: 12.5%;
    width: 100%;
    left: 0;
    box-shadow: 10px 10px 50px #262629;
    background-color: #fff;
    padding: 50px 20px;
}

@media (min-width: 768px) {
    .content {
        left: 12.5%;
        width: 75%;
    }
}
