.navButton {
    display: flex;
    border: none;
    background: none;
    font-size: 20px;
    color: #00004d;
    cursor: pointer;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .3);

    & > *:not(:first-child) {
        margin-left: 10px;
    }
}

.displayNone {
    display: none;
}
