.content {
    text-align: left;
    min-height: 65px;
    border: none !important;

    &:not(.ignoreDropCap):first-letter {
        color: #00004d;
        float: left;
        line-height: 55px;
        font-size: 80px;
        font-family: Montserrat;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 5px;
        margin-bottom: -5px;
    }
}
