.previewWrapper {
    position: relative;
    padding: 10px 0;
    box-shadow: 10px 10px 25px rgba(0, 0, 77, .1);
    margin-bottom: 40px;
}

.readMore {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 -10px 20px #fff;
    background-color: rgba(255, 255, 255, .8);

    & > a {
        color: #000080;
        text-decoration: none;
        font-size: 20px;
        padding: 5px;
    }
}