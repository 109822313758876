.siteHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    color: #fff;
    z-index: 10;
    height: 92px;

    & * {
        z-index: 10;
    }

    & a {
        color: #fff;
        text-decoration: none;
        line-height: 19px;
    }

    & > nav > ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > li {
            margin-left: 20px;
            font-size: 15px;
        }
    }
}

.fixed {
    background-color: #000033;
    position: fixed;
    height: 50px;
    top: 0;
    right: 0;
    left: 0;
}

.spacer {
    height: 92px;
}

.categoryList {
    position: absolute;
    background-color: #262629;
    padding: 20px;
    margin-top: 5px;
    margin-left: -20px;
}

.catButton {
    background: none;
    border: none;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    text-transform: capitalize;
}
