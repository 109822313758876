.content {
    position: absolute;
    top: 150px;
    width: 100%;
    left: 0;
    box-shadow: 10px 10px 50px #262629;
    background-color: #fff;
    padding: 20px 20px;
}

@media (min-width: 768px) {
    .content {
        width: 75%;
        left: 12.5%;
    }
}

.hero {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    background-image: url('https://ceadventures.life/wp-content/uploads/2020/04/cropped-cropped-cropped-bell_harbor_marina_1-1.jpg');
}

.categoryLabel {
    color: #000080;
    letter-spacing: 5px;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.titleCase {
    color: #000080;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.outer {
    position: absolute;
    display: inline-block;
    padding: 10px;
    text-shadow: 1px 1px 3px rgba(0, 0, 51, .3);
    box-shadow: 10px 10px 50px #000033;
    background-color: rgba(0, 0, 51, .8);;
}
