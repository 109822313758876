.landingPage {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    background-repeat: no-repeat;
    background-position: center;
}

.titleWrapper {
    color: #fff;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    width: 100%;
    padding-top: 5px;

    & > h2 {
        position: relative;
        display: inline-block;
        padding-bottom: 10px;
        font-size: 57px;
        line-height: 67px;
        font-weight: 700;
        letter-spacing: 1px;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .3);

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 40%;
            height: 1px;
            margin-left: -20%;
            background-color: #fff;
            box-sizing: border-box;
        }
    }
}

.content {
    position: absolute;
    top: 75%;
    width: 100%;
    left: 0;
    box-shadow: 10px 10px 50px #262629;
    background-color: #fff;
    padding: 50px 20px;
}

@media (min-width: 768px) {
    .content {
        left: 12.5%;
        width: 75%;
    }
}
