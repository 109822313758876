.navWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;

    & > a {
        text-decoration: none;
        padding: 5px;
        box-shadow: 0 10px 20px rgba(0, 0, 77, .1);
        font-size: 14px;
        display: flex;
        align-items: center;
        max-width: calc(50% - 5px);
        white-space: nowrap;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            box-shadow: none;
            background-color: rgba(0, 0, 77, .025);
            border: 1px solid rgba(0, 0, 77, .05);
        }
    }
}

.singleNav {
    justify-content: flex-end;
}
