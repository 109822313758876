@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.editor-background {
    background-image: url('https://ceadventures.life/wp-content/uploads/2020/04/cropped-istock-464852512-copy-1.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.editor-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    margin: auto;
    height: calc(100vh - 125px);
    position: relative;

    &>div {
        width: 100%;
    }

    .capitalize-transform {
        text-transform: capitalize;
    }

    .new-post-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        padding: 50px;
        border: 1px solid #ccc;
        border-left: none;
    
        &>label {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
    
            &>select,
            &>input {
                margin-top: 10px;
                width: 100%;
                max-width: 300px;
                padding: 5px;
                font-size: 16px;
            }
        }
    
        &>button {
            padding: 10px;
            background: #00004d;
            color: #fff;
            font-size: 18px;
            align-self: center;
            border: none;
        }
    }
}

#editor {
    height: calc(100vh - 167px);
    overflow: auto;
}

.ql-hr {
    content: 'hr'
}

.ql-toolbar, #editor, .new-post-form {
    background: #fff;
}
