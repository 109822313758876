.titleCase {
    text-transform: capitalize;
    margin: 0;
    font-size: 35px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
    letter-spacing: 1px;
    color: #00004d;
    margin-bottom: 15px;
    margin-top: 20px;

    & > a {
        color: #00004d;
        text-decoration: none;
    }
}
