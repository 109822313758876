.articleHeader {
    display: block;
    margin-bottom: 30px;
}

.articleDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & * {
        text-decoration: none;
        color: #000080;
        font-size: 16px;
    }

    & > div {
        & > * {
            margin: 0px;
        }
    }
}

.articleAuthorDate {
    display: flex;
    
    & > *:not(:first-child) {
        margin-left: 10px;
    }
}
